import React, {useContext} from 'react'
import { graphql, Link } from 'gatsby'
import Header from "@menus/header"
import HeroMenu  from '@menus/HeroMenu'
import Seo from  "@components/Seo"
import HomeHeroSection from '@loggedOut/HomeHeroSection'
import styled from 'styled-components'
import {FirebaseContext} from '@components/Firebase'
import { useTranslation } from 'react-i18next'

const StoryListItem = styled.div`
  display:flex;
  height:75px;
  margin-bottom:10px;
  @media ${props =>props.theme.desktopUp} {
    margin-right:10px;
    width:400px;
  }
`

const StoryListWrapper = styled.div`
 @media ${props =>props.theme.desktopUp} {
  }
  display: flex;
  flex-wrap: wrap;
  width:100%;
  justify-content: start;
`


const StoryTitle= styled.h2`
  font-size:1.3rem;
  margin:0px;
`

const StoryInfoWrapper = styled.div`
  padding-left:20px;
  margin: auto 0px ;
 
`
const StorySectionWrapper = styled.div`
 @media ${props =>props.theme.desktopUp} {
    justify-content: start;
    width:75%;
  }
  display:flex;
  flex-direction:column;
  width:90%;
  justify-content: center;
  margin:auto;
`

const Fantasies = (props) => {
  const {user, } = useContext(FirebaseContext);
  const { t } = useTranslation()
  const lang = props.pageContext.lang


  const fantasies = [...props.data.allFantasy.edges].sort((a, b) => 
    a.node[lang].fantasy.localeCompare(b.node[lang].fantasy)
  )

  return (
    <>
      <Header location={props.location} />
      <>
        <Seo
          title={"Stories"}
          description={"Audio erotica for women & couples ✅ Short 5-20 min stories ✅ Respectful &amp; relatable ✅ Professional voice actors with sexy voices ✅ Explore new fantasies"}
        />
        {!user && <>
          <HeroMenu />
          <HomeHeroSection
            headerTitle={"Fantasies"}
            headerText={"Explore countless sexy fantasies for any taste by listening to our erotic audio stories."}
            ctaText={t("seo-landingpage.cta-1")}
            showText
          />
        </>}

        <StorySectionWrapper>
          <StoryListWrapper>
            {fantasies.map(fantasy => (
              <Link to={`${t('urls.fantasy')}${fantasy.node[lang].slug}`} key={fantasy.node.id}>
                <StoryListItem>
                  <StoryInfoWrapper>
                  <StoryTitle>{fantasy.node[lang].fantasy}</StoryTitle>
                  </StoryInfoWrapper>
                </StoryListItem>
              </Link>
            ))}
          </StoryListWrapper>
        </StorySectionWrapper>
      </>
    </>
  )
}

export const query = graphql`{
  allFantasy {
    edges {
      node {
        id
        languages
        en {
          fantasy
          slug
        }
        es {
          fantasy
          slug
        }
        de {
          fantasy
          slug
        }
      }
    }
  }
}
` 




 
export default Fantasies